<div class="ui padded container">
  <h1 class="ui header">Kontakt</h1>
  <div class="ui grid">
    <div class="five wide column">
      <h3>Technische Probleme und Schwierigkeiten</h3>
      <p>
        Bei technischen Problemen beschreiben Sie Ihr Anliegen bitte ausführlich und fügen nach Möglichkeit
        einen Screenshot des Problems und ihren verwendeten Browser an.
      </p>
    </div>
    <div class="five wide column">
      <h3>Fragen und Hinweise</h3>
      <p>
        Bei Fragen und für Hinweise können Sie sich gerne direkt an das FIT-Team der Universität Kassel wenden.
        Wir werden versuchen Ihr Anliegen schnellst möglich zu bearbeiten.
      </p>
    </div>
    <div class="four wide column">
      <h3>Kontaktmöglichkeiten</h3>
      <p>
        Email: <a href="mailto:fit@uni-kassel.de">fit&#64;uni-kassel.de</a>
      </p>
    </div>
  </div>
</div>
